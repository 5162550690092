import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import Banner from '../components/banner'
import Sidebar from '../components/sidebar'
import Seo from '../components/seo'

import SeoImage from '../assets/images/promo-hrt.jpg'

class HRCVerona extends React.Component {
    render() {
        return (
            <StaticQuery
            query={graphql`
                query HRCVeronaMeta {
                    site {
                        siteMetadata {
                            title
                            description
                            emailUrl
                            phone
                            google {
                                googleMapAPIKey
                                googleReCaptchaKey
                            }
                        }
                    }
                }
            `}
            render={data => (
                <>
                <Layout>
                    <Seo title={`Hormone Replacement Therapy Verona, NJ | ${data.site.siteMetadata.title}`} description={`Quality hormone therapy in Verona helps to counter the age-related hormone declines that lead to andropause and other hormone imbalances, and can help to not only relieve symptoms but also improve quality of life.`} image={SeoImage} keywords={[``]} />
                    <Banner title='Hormone Replacement Therapy Verona, NJ' subTitle='' titleColor='#FFFFFF' subTitleColor='#FFFFFF' image={SeoImage} />
                    <section>
                        <Container>
                            <Row>
                                <Col xs='12' sm='8' md='8' lg='8'>
                                    <p class='introduction'>As we age, the declining function of our reproductive organs causes decreased levels of the hormones that are responsible for a variety of important functions within the body. Quality hormone therapy in Verona helps to counter the age-related hormone declines that lead to andropause and other hormone imbalances, and can help to not only relieve symptoms but also improve quality of life.</p>

                                    <p>Declining hormone production can lead to a variety of symptoms, including:</p>

                                    <ul>
                                        <li>weight gain</li>
                                        <li>fatigue</li>
                                        <li>difficulty losing weight</li>
                                        <li>difficulty gaining muscle</li>
                                        <li>sexual dysfunction</li>
                                        <li>loss of libido</li>
                                        <li>difficulty sleeping</li>
                                        <li>mood changes</li>
                                        <li>…and plenty more</li>
                                    </ul>

                                    <p>Hormone or testosterone replacement therapy in Verona can help combat all of these symptoms and help you return to a time where these issues were few and far between.</p>

                                    <p>Hormone receptors are found in all areas of the body, and imbalances will naturally affect your health in a negative way. Recently, age-related hormone decline has been more prevalent than ever, with testosterone and hormone decline affecting younger and younger individuals.</p>

                                    <p>Fortunately, testosterone replacement therapy is an effective treatment option for individuals with low testosterone and, when replaced, most positive effects of healthy testosterone levels are restored.</p>

                                    <p>At Optimal Health and Wellness in Veronal, Dr. Sambataro, a member of the Androgen Society, is constantly staying up to date on the latest research in hormone and testosterone therapy. Anyone who is experiencing the above symptoms, even if you are experiencing these issues at a younger age, should absolutely reach out to Dr. Sambataro in Verona today.</p>

                                    <h2>What is Hormone Replacement Therapy?</h2>

                                    <p>There are a few options out there for hormone therapy.</p>

                                    <p><em>Intramuscular testosterone injections</em> is a solution that requires injections in the buttocks every two to four weeks from your doctor. <em>Testosterone patches</em> is a form of testosterone replacement therapy that uses a daily patch applied to either your back, buttocks, arms or abdomen. Finally, <em>topical testosterone gel</em> can be a solution that requires a daily application to the arms, shoulder, or abdomen.</p>

                                    <p>A conversation with Dr. Sambataro can help determine what type of testosterone replacement therapy is right for you.</p>

                                    <h2>Risks and Candidacy</h2>

                                    <p>It is important to establish one’s candidacy for testosterone replacement therapy. As with any type of exercise, therapy, surgery, or procedure, there are inherent risks for hormone therapy. A healthy individual working with a reputable doctor such as Dr. Sambataro in Verona should have minimized side effects, but they do sometimes occur.</p>

                                    <p>Side effects can include:</p>

                                    <ul>
                                        <li>fluid retention</li>
                                        <li>acne</li>
                                        <li>breast enlargement</li>
                                        <li>decreased testicle size</li>
                                        <li>decreased sperm count</li>
                                        <li>increased cholesterol</li>
                                        <li>infertility</li>
                                        <li>high blood pressure</li>
                                        <li>increased red blood cells</li>
                                    </ul>

                                    <p>While the list of side effects can seem daunting, keep in mind that most individuals do not experience detrimental side effects and instead find a solution to their testosterone-related issues. Most individuals will regain the benefits of normal testosterone levels, and experience minor side effects at worst.</p>

                                    <p>It is important to be open and transparent in your consultation to ensure that no underlying health concerns could potentially cause an increase in the risk of side effects.</p>

                                    <h2>Schedule a Consultation</h2>

                                    <p>If you believe that testosterone replacement therapy in Verona could be an effective solution for your testosterone issues, <a href={`tel:${data.site.siteMetadata.phone}`} rel='noopener noreferrer'>call today</a> to schedule a consultation.</p>
                                </Col>
                                <Sidebar title={data.site.siteMetadata.title} phone={data.site.siteMetadata.phone} email={data.site.siteMetadata.emailUrl} hasCare={true} hasContact={true} />
                            </Row>
                        </Container>
                    </section>
                </Layout>
                </>
            )}
            />
        )
    }
}

export default HRCVerona